import React from 'react';

import axios from 'axios';

import logo from './logo.svg';
import './App.css';

import searchIcon from './search.svg';

import Webcam from "react-webcam";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

function App() {
  const [lineNumber, setLineNumber] = React.useState("")
  const [title, setTitle] = React.useState("Position an item in the frame and click the search button.")
  const [confidence, setConfidence] = React.useState(1)

  function getProduct(base64Image){
    axios({
      method: 'post',
      url: 'https://9arqv3j58a.eu-west-1.awsapprunner.com/getProducts',
      data: {
        image: base64Image
      }
    }).then((res) => {
      setLineNumber(res.data.results[0].product.name.replace("projects/wr-prods/locations/europe-west1/products/", ""))
      setTitle(res.data.results[0].product.displayName)
      setConfidence(res.data.results[0].score)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handle = useFullScreenHandle();

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      getProduct(imageSrc)
    },
    [webcamRef]
  );

  return (
    <FullScreen handle={handle}>
      <div className="h-screen flex flex-col">
        <header className="bg-[#578626] text-white py-2 text-center" onClick={handle.enter}>
        <h1 className="text-2xl" onClick={handle.enter}>Waitrose ML Demo</h1>
        </header>

        <div className="h-full flex flex-col justify-center bg-black">
            <Webcam 
                width="100%"
                height="100%"
                videoConstraints={{
                    facingMode: "environment"
                }}
                ref={webcamRef}
            />
        </div>

        <div className="bg-black text-center grid grid-cols-12 h-48">
            <div className="col-span-2 flex">
              <button onClick={capture} className="p-4 w-full text-center" style={{backgroundColor: "#154835"}}><img className="mx-auto" style={{filter: "invert(1)"}} src={searchIcon} /></button>
            </div>
            { confidence >= 0.4 && <div className="col-span-10 bg-yellow text-white text-left flex items-center" style={{backgroundColor: "#578626"}}>
              { lineNumber != "" && <img src={'https://ecom-su-static-prod.wtrecom.com/images/products/11/LN_' + lineNumber + '_BP_11.jpg'} className="h-20" /> }
              <div className="ml-2">
                <h1 className="text-3xl font-bold">{lineNumber}</h1>
                <h1 className="text-3xl">{title}</h1>
              </div>
            </div> }

            { confidence < 0.4 && <div className="col-span-10 bg-yellow text-white text-left flex items-center" style={{backgroundColor: "#F19100"}}>
              { lineNumber != "" && <img src={'https://ecom-su-static-prod.wtrecom.com/images/products/11/LN_' + lineNumber + '_BP_11.jpg'} className="h-20" /> }
              <div className="ml-2">
                <h1 className="text-3xl font-bold">{lineNumber}</h1>
                <h1 className="text-3xl">{title}</h1>
              </div>
            </div> }
        </div>
      </div>
    </FullScreen>
  );
}

export default App;
